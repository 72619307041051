@font-face {
	font-family: 'F37Ginger-Regular';
	src: url('../assets/fonts/F37Ginger-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'F37Ginger-Bold';
	src: url('../assets/fonts/f37ginger-bold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'C-Bold';
	src: url('../assets/fonts/C_Bold.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'C-Medium';
	src: url('../assets/fonts/C_Medium.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'C-Regular';
	src: url('../assets/fonts/C_Regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

input[type='password'] {
	font: small-caption;
}

@font-face {
	font-family: 'TitleUltraHeavy';
	src: url('../assets/fonts/BloomSpeakTitle-UltraHeavy.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

a:active,
a:focus {
	background: transparent;
}

.ant-notification {
	z-index: 1000000 !important;
}

.ant-tooltip-inner {
	background: #000 !important;
}

.ant-tabs-tab {
	color: #FFF;
}

/* .ant-tabs-nav-container {
	background-color: rgb(25 25 25 / 80%);
} */

.ant-modal-wrap {
	z-index: 1000000 !important;
}

.label {
	font-size: 18px;
	color: #989998;
	line-height: 1.5;
	text-align: left;
	text-transform: none;
	font-weight: normal;
}
